<template>
  <div>
    <div class="lg:p-12 max-w-xl lg:my-0 my-12 mx-auto p-6 space-y-">
      <form class="lg:p-10 p-6 space-y-3 relative bg-white shadow-xl rounded-md" v-on:submit.prevent="validateCode">
        <h1 class="lg:text-2xl text-xl font-semibold mb-6">Xác thực mã</h1>
        <div :class="{'bg-green-500': status === 'success', 'bg-red-500': status === 'error'}" class="border p-4 relative rounded-md uk-alert" v-if="message">
          <p class="text-white">{{ message }}</p>
        </div>
        <div>
          <label class="mb-0" for="code">Nhập mã xác thực:</label>
          <input v-model="code" id="code" type="text" placeholder="" class="bg-gray-100 h-12 mt-2 px-3 rounded-md w-full">
        </div>
        <div>
          <button type="submit" class="bg-blue-600 font-semibold p-2 mt-5 rounded-md text-center text-white w-full">
            Xác thực mã
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
  name: 'ValidateCode',
  data() {
    return {
      code: "",
      identity: "",
      message: "",
      disabled: false,
      status: "error"
    }
  },
  methods: {
    validateCode() {
      this.disabled = true;
      this.message = "";

      let query = `mutation($identity: String!, $code: String!, $type: String!) {
        validateCode(identity: $identity, code: $code, type: $type)
      }`;

      ApiService.graphql(query, {identity: this.identity, code: this.code, type: this.type})
          .then(({data}) => {
            if (data.data && data.data.validateCode) {
              this.$router.push({name: "Login", query: {ref: 'validate'}});
            } else {
              this.disabled = false;
              this.message = data.errors[0].message;
              this.status = "error";
            }
          })
          .catch((response) => {
            this.disabled = false;
            this.message = response.message;
            this.status = "error";
          });
    }
  },
  created() {
    this.code = this.$route.query.code || "";
    this.type = this.$route.query.type || "";
    this.identity = this.$route.query.identity || "";

    if (this.code) {
      this.validateCode();
    }
  }
}
</script>
